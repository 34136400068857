import { config } from "$applib/configs/application";
import { CustomDomEvent } from "$applib/enums/events";
import { resetEventHandlersFactory } from "$applib/utils/event-handlers";
import { buildHeaders, getCsrfTokenHeader } from "$applib/utils/headers";
import { snakeCaseKeys } from "$applib/utils/objects";
import { parameterizePath } from "$applib/utils/resources/urls";

import type { AppointmentForm } from "./appointment-form";

import type { AppointmentTimeSlot } from "$applib/types/resources/appointments";

import { getTimeSlotId, renderTimeSlot } from "./shared/utils/appointment-grid";

const CREATE_APPOINTMENT_CLASS = "js-create-appointment-submit-button";

const { urls } = config;
const { path: newAppointmentEndpoint } = urls.api.appointments.new;

// allows us to use .handleEvent as an unchanging function, required when
// removing event listeners - event listeners cannot remove dynamically created
// functions
function handlerFactory() {
	let form: AppointmentForm;

	function setForm(appointmentForm: AppointmentForm) {
		form = appointmentForm;
	}

	function handleEvent() {
		return createAppointment(form);
	}

	return { handleEvent, setForm };
}
const handler = handlerFactory();

const resetCreateAppointmentHandlers = (appointmentForm: AppointmentForm) => {
	handler.setForm(appointmentForm);

	return resetEventHandlersFactory({
		className: CREATE_APPOINTMENT_CLASS,
		handler: handler.handleEvent,
		eventType: "click",
	});
};

function createAppointment(appointmentForm: AppointmentForm) {
	const { elements } = appointmentForm;
	const { inputs } = elements;
	const appointmentDate = inputs.dateInput.value;
	const appointmentTime = inputs.timeInput.value;
	const staffResourceId = inputs.staffResourceIdInput.value || "";
	const patientId = inputs.patientIdInput.value || "";
	const serviceId = inputs.serviceIdSelect.value || "";
	const sendEmailNotification = inputs.sendEmailNotificationInput.checked;
	const submitButtonEl = inputs.submitButton;
	const submitButtonOriginalContent = submitButtonEl.textContent || "";
	const body = {
		appointment_date: appointmentDate,
		appointment_service_id: serviceId,
		appointment_time: appointmentTime,
		notify_patient: sendEmailNotification,
		patient_id: patientId,
		staff_resource_id: staffResourceId,
	};
	const url = parameterizePath(
		newAppointmentEndpoint,
		snakeCaseKeys({ resourceId: staffResourceId }),
	);

	appointmentForm.setFormError("");
	appointmentForm.setSubmitButtonAttributes({
		disabled: true,
		textContent: "Booking Appointment...",
	});

	fetch(url, {
		method: "POST",
		headers: buildHeaders({
			...getCsrfTokenHeader(),
			Accept: "application/json",
			"Content-Type": "application/json",
		}),
		body: JSON.stringify(body),
	})
		.then((response) => response.json())
		.then((response) => {
			appointmentForm.setSubmitButtonAttributes({
				disabled: false,
				textContent: submitButtonOriginalContent,
			});

			if (response.instance !== "Success") {
				const errorMessage = getErrorMessage(response);

				appointmentForm.setFormError(errorMessage);

				return;
			}

			const timeSlot: AppointmentTimeSlot = response.result;
			const timeSlotId = getTimeSlotId(timeSlot);
			const timeSlotEl = document.getElementById(timeSlotId);

			if (timeSlotEl) {
				timeSlotEl.outerHTML = renderTimeSlot(timeSlot);

				const event = new CustomEvent(
					CustomDomEvent.AppointmentGridAppointmentCreated,
				);
				document.dispatchEvent(event);

				appointmentForm.reset();
				hideModal();
			} else {
				console.error(
					"Forcing reload of page - unable to find time slot element to replace",
				);
				window.location.reload();
			}
		})
		.catch((response) => {
			const errorMessage = getErrorMessage(response);

			appointmentForm.setFormError(errorMessage);
			appointmentForm.setSubmitButtonAttributes({
				disabled: false,
				textContent: submitButtonOriginalContent,
			});
		});
}

function hideModal() {
	const appointmentModal = document.getElementById("appointmentModal");

	if (appointmentModal) {
		appointmentModal.style.display = "none";
		appointmentModal.classList.remove("show");
		appointmentModal.setAttribute("aria-hidden", "true");
	}

	document.body.classList.remove("modal-open");
	document.body.style.removeProperty("padding-right");

	const modalBackdropEls = document.getElementsByClassName(
		"modal-backdrop fade show",
	);

	if (modalBackdropEls.length > 0) {
		modalBackdropEls[0].outerHTML = "";
	}
}

function getErrorMessage(
	response: Record<string, string | Record<string, string>> = {},
) {
	const data = response.responseJSON || response;
	// TODO: LB - determine what the actual type is here, instead of doing this
	// ridiculous Typescript dance
	const error =
		typeof data === "object" && typeof data.error !== "string"
			? data.error || {}
			: {};

	let message = "There was a problem booking an appointment";

	switch (true) {
		// DjangoApiError
		case Array.isArray(error.message):
			message = error.message.join(". ");
			break;
		// ApiError
		case typeof error.message === "object":
			message = Object.values(error.message).join(". ");
			break;
		case typeof error.message === "string":
			try {
				const errors = JSON.parse(error.message);

				message = Object.values(
					errors as Record<string, Array<{ message: string }>>,
				)
					.flatMap((xs) => xs.map((x) => x.message))
					.join("<br>");
			} catch (err) {
				console.error(err);
				message = error.message;
			}
			break;
	}

	return message;
}

export { resetCreateAppointmentHandlers };
